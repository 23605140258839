@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css';
body {
  background: #266;
}
.Modal {
  position: absolute;
  background: #155;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-size: cover;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 25%) 0px 14px 28px, rgb(0 0 0 / 22%) 0px 10px 10px;
  border: 1px #fff5 solid;
  padding: 0 10px;
  min-width: 200px;
}
.Modal a {
  display: block;
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  margin: 4px 0 10px 0;
  margin: 10px;
}
.Modal a:hover {
  text-decoration: underline;
}

.Input {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-bottom: 1px solid #58a4b0;
  margin: 0 20px;
}
.Input input {
  border: 0;
  background: transparent;
  padding: 2px 10px;
  margin: 18px 4px 0 4px;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
}
.Input input:required {
  box-shadow: none;
}
.Input input:focus ~ label {
  opacity: 1;
}
.Input input[type=text] ~ label::before {
  content: "";
}
.Input input[type=password] ~ label::before {
  content: "";
}

label {
  font-family: FontAwesome;
  color: white;
  font-size: 14px;
  margin-top: 17px;
  transition: opacity 0.3s ease-in-out;
}
label::before {
  padding: 5px 5px 0 0;
  opacity: 0.5;
}

form button {
  position: relative;
  margin: 15px 0 25px 0;
  left: 50%;
  transform: translateX(-50%);
  background: #055;
  border-radius: 5px;
  padding: 10px;
  color: white;
  width: 85%;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  cursor: pointer;
  border: 1px #fff5 solid;
}


.logo {
  text-align: center;
  color: white;

}
.logo img {
  display: block;
  margin: 20px auto;
  font-size: 70px;
  transform: translateX(-5px);
}
.logo span {
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}
