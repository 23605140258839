div#root{
  overflow: hidden;
}
.App {
  text-align: center;
  background-color: #266;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navBar {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
}

.menuBtn {
  margin: 15px 5px 0 0;
  border: none;
  padding: 2px 8px;
  border-radius: 3px;
  background: #eee;
  color: #055;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}
footer {
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100vw;
}
footer  div {
  margin: 2px 3px;
}